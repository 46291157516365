<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('event.directElevator')" name="first" v-if="mbAuth">
      <mb-list></mb-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('event.escalator')" name="second" v-if="nbAuth && false">
      <nb-list></nb-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('event.newEvent')" name="three" v-if="deviceEventAuth">
      <device-event-list></device-event-list>
    </el-tab-pane>
    <el-tab-pane :label="$t('event.elevatorDPC')" name="four" v-if="deviceEventAuth && $i18n.isCn">
      <alarm-list></alarm-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import MbList from "@/views/event/MbList";
import NbList from "@/views/event/NbList";
import DeviceEventList from "@/views/event/DeviceEventList";
import AlarmList from "@/views/event/ElectroAlarmList";

export default {
  components: { NbList, MbList, DeviceEventList, AlarmList },
  data() {
    return {
      mbAuth: this.$auth(45),
      nbAuth: this.$auth(45),
      deviceEventAuth: this.$auth(45),
      carAuth: this.$auth(45),
      activeName: "first",
    };
  },
  mounted() {
    if (this.mbAuth) {
      this.activeName = "first";
      return;
    }
    if (this.nbAuth) {
      this.activeName = "second";
      return;
    }
    if (this.deviceEventAuth) {
      this.activeName = "three";
      return;
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
